/* font */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');
@import url('//fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Roboto&display=swap');
@import url('//cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');
/* Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* SCoreDream */
@import url('https://webfontworld.github.io/score/SCoreDream.css');
@font-face {
  font-family: 'GmarketSans';
  font-weight: 300;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff')
      format('woff'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.ttf')
      format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'GmarketSans';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff')
      format('woff'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.ttf')
      format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff')
      format('woff'),
    url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.ttf')
      format('truetype');
  font-display: swap;
}

/* common color */
:root {
  --color-main: #001650;
  --color-point-sub: #c30f23;
  --color-point-sub2: #dea359;
  --color-sub-bg: #f7f9fc;
  --color-white: #fff;
  --color-black: #000;
  --color-blue-0e4194: #0e4194;
  --color-red-FF3200: #ff3200;
  --color-gray-f7f7f7: #f7f7f7;
  --color-gray-f5f5f5: #f5f5f5;
  --color-gray-eaeaea: #eaeaea;
  --color-gray-DCDCDC: #dcdcdc;
  --color-gray-d9d9d9: #d9d9d9;
  --color-gray-c8c8c8: #c8c8c8;
  --color-gray-a0a0a0: #a0a0a0;
  --color-gray-969696: #969696;
  --color-gray-737373: #737373;
  --color-gray-3c3c3c: #3c3c3c;
  --color-gray-323232: #323232;
  --color-gray-F6F8FC: #f6f8fc;
  --color-2abfb8-rgba-10: rgba(42, 191, 184, 0.1);
  --color-2abfb8-rgba-20: rgba(42, 191, 184, 0.2);
  --color-2abfb8-rgba-50: rgba(42, 191, 184, 0.5);
  --color-2abfb8-rgba-85: rgba(42, 191, 184, 0.85);
  --color-white-rgba-10: rgba(255, 255, 255, 0.1);
  --color-white-rgba-25: rgba(255, 255, 255, 0.25);
  --color-white-rgba-50: rgba(255, 255, 255, 0.5);
  --color-white-rgba-85: rgba(255, 255, 255, 0.85);
  --color-black-rgba-5: rgba(0, 0, 0, 0.05);
  --color-black-rgba-10: rgba(0, 0, 0, 0.1);
  --color-black-rgba-25: rgba(0, 0, 0, 0.25);
  --color-black-rgba-50: rgba(0, 0, 0, 0.5);
  --color-black-rgba-85: rgba(0, 0, 0, 0.85);

  /* --sub-tab-menu-height: 6rem; */
  --sub-tab-menu-height: 9rem;

  --font-Pretendard: 'Pretendard';
  --font-Roboto: 'Roboto', sans-serif;
  --font-GmarketSans: 'GmarketSans';
  --font-SCoreDream: 'SCoreDream';
  --font-Montserrat: 'Montserrat', sans-serif;

  /*--cantainer-width: 1648px;  24 + 1600 + 24 */
  --cantainer-width: 1328px; /* 24 + 1280 + 24 */

  --swiper-navigation-size: 22px;
  --swiper-theme-color: #000;
}
:lang(ko) {
  font-family: 'Pretendard';
}

/* Reset */
* {
  word-break: keep-all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
blockquote,
pre,
code,
address,
ul,
ol,
li,
nav,
section,
article,
header,
footer,
main,
aside,
dl,
dt,
dd,
table,
thead,
tbody,
tfoot,
label,
caption,
th,
td,
form,
fieldset,
legend,
hr,
input,
button,
textarea,
object,
figure,
figcaption {
  margin: 0;
  padding: 0;
}
body,
input,
select,
textarea,
button,
img,
fieldset {
  border: none;
}
ul,
ol,
li {
  list-style: none;
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
legend,
caption,
.sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
}
address,
cite,
code,
em,
i {
  font-style: normal;
  font-weight: normal;
}
label,
img,
input,
select,
textarea,
button,
a {
  vertical-align: middle;
}
img {
  max-width: 100%;
}
ins,
a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
/* Normalize */
::-moz-selection {
  background: var(--color-main);
  color: var(--color-white);
}
::selection {
  background: var(--color-main);
  color: var(--color-white);
}
html {
  font-size: 10px;
}
body {
  font-size: 1.5rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: var(--color-gray-323232);
}
/* 폼관련 요소 */
select,
input,
textarea {
  /* width: 100%; */
  vertical-align: middle;
  border-radius: 0;
  outline: none;
  border: 0;
  padding: 0.8rem;
  color: var(--color-black);
  background: var(--color-white);
  box-shadow: none;
}
input[type='text'],
input[type='submit'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='reset'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='file'],
input[type='email'],
input[type='tel'],
select {
  font-weight: 400;
  border: 1px solid var(--color-gray-d9d9d9);
}
input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  top: auto;
  left: 0;
  opacity: 0;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='text']:focus,
input[type='password']:focus,
input[type='tel']:focus,
input[type='email']:focus,
input[type='search']:focus,
textarea:focus {
  border-color: var(--color-gray-969696);
  background-color: var(--color-white);
  outline: none;
}
input[readonly] {
  pointer-events: none;
}
::-webkit-input-placeholder {
  font-size: inherit;
  color: var(--color-gray-737373);
}
::placeholder {
  font-size: inherit;
  color: var(--color-gray-737373);
}
textarea {
  overflow: auto;
  resize: none;
  border: 1px solid var(--color-gray-d9d9d9);
}
select::-ms-expand {
  display: none;
}
select {
  position: relative;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  min-width: 10rem;
  padding: 0 0 2px 12px;
  -webkit-padding-end: 30px;
  -moz-padding-end: 30px;
  /* line-height: 27px; */
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAANElEQVQYV2Ncvnz5fwYiACPRCkGGEVIcGRnJyAizFZdikCKQGrhCbCbDFGEoRFaMrAgkDgB1ORXvQyzzggAAAABJRU5ErkJggg==)
    no-repeat right 1.2rem center;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
/* checkbox */
input[type='checkbox'] {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  /* color: #363839; */
  border: 1px solid #bdc1c6;
  /* border-radius: 4px; */
  appearance: none;
  outline: 0;
  cursor: pointer;
  opacity: 1;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
input[type='checkbox']::before {
  content: '';
  position: absolute;
  display: block;
  top: 0px;
  left: 5px;
  width: 5px;
  height: 10px;
  border-style: solid;
  border-color: var(--color-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
input[type='checkbox']:checked {
  color: var(--color-white);
  border-color: var(--color-main);
  background: var(--color-main);
}
input[type='checkbox']:checked::before {
  opacity: 1;
}
input[type='checkbox']:checked ~ label::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
input[type='checkbox'] ~ label {
  position: relative;
  cursor: pointer;
  padding: 0 0.25rem 0 0.5rem;
  user-select: none;
}
input[type='checkbox'] ~ label::before {
  content: attr(data-content);
  position: absolute;
  color: #9c9e9f;
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: #363839;
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
/* radio */
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: var(--color-white);
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--color-main);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

i[class*='ri-'] {
  vertical-align: middle;
}

.scrollBar-custom {
  overflow-y: auto;
}
.scrollBar-custom::-webkit-scrollbar {
  width: 4px;
}
.scrollBar-custom::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 30px;
}
.scrollBar-custom::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 30px;
}
.scrollBar-custom:focus::-webkit-scrollbar-thumb {
  border: 1px solid #c2c2c2;
}

/* 스킵네비게이션 */
#skipNavi {
  position: absolute;
  top: -1px;
  left: 0;
  background-color: #333;
  z-index: 999999;
}
#skipNavi a {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 25px;
  margin-top: -100px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background: #000;
}
#skipNavi a:link {
  color: #333;
}
#skipNavi a:focus {
  margin-top: 0;
  text-decoration: underline;
  color: var(--color-white);
}
/* list */
ul[class*='ul__'] li {
  position: relative;
  padding-left: 1.2rem;
}
ul[class*='ul__'] li:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0px;
  display: inline-block;
  width: 6px;
  height: 1px;
  background: var(--color-gray-c8c8c8);
}
ul[class*='ul__'] > li + li {
  margin-top: 0.5rem;
}
/* ol list */
ol[class*='__num'] li {
  position: relative;
  padding-left: 2.8rem;
}
ol[class*='__num'] > li + li {
  margin-top: 1rem;
}
ol[class*='__num'],
ol[class*='__num'] > li > ol,
ol[class*='__num'] > li > ol > li > ol {
  counter-reset: item 0;
}
ol[class*='__num'] > li,
ol[class*='__num'] > li > ol > li,
ol[class*='__num'] > li > ol > li > ol > li {
  position: relative;
  counter-increment: item 1;
}
ol[class*='__num'] > li:before,
ol[class*='__num'] > li > ol > li:before,
ol[class*='__num'] > li > ol > li > ol > li:before {
  content: counter(item);
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  color: var(--color-gray-737373);
  font-size: 1.1rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  background-color: var(--color-white);
}
ol[class*='__num'] > li:before {
  color: var(--color-gray-737373);
  border-color: var(--color-gray-c8c8c8);
}
ol[class*='__num'] > li > ol,
ol[class*='__num'] > li > ul {
  margin-top: 0.8rem;
}
ol[class*='__num'] > li > ol > li:before {
  font-size: 1.2rem;
  border-color: transparent;
}
ol[class*='__num'] > li > ol > li {
  padding-left: 2.2rem;
}
ol[class*='__num'] > li > ol > li + li {
  margin-top: 0.5rem;
}
ol[class*='__num'] > li > ol > li > ol > li:before {
  content: counter(item) '.';
  border-color: var(--color-white);
}
ol[class*='__num'] > li > ul > li {
  padding-left: 1.4rem;
}
ol[class*='__num'] > li > ul > li:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0px;
  display: inline-block;
  width: 6px;
  height: 1px;
  background: var(--color-gray-c8c8c8);
}
/* 이용약관, 개인정보처리방침, 이메일무단수집거부 등  */
.terms__txt {
  padding: 3.2rem;
  color: var(--color-black);
  font-size: 1.7rem;
  font-weight: 500;
  border-radius: 1rem;
  background: var(--color-gray-F6F8FC);
}
.terms__box {
  margin-top: 3.2rem;
}
.terms__box + .terms__box {
  padding-top: 3.2rem;
  border-top: 1px dashed var(--color-gray-d9d9d9);
}
.terms__txt--tit {
  position: relative;
  margin-bottom: 2.4rem;
  color: var(--color-main);
  font-size: 1.9rem;
  font-weight: 700;
}
.terms__txt--tit2 {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.terms__txt--inner {
  margin-bottom: 1rem;
}
.terms__txt--inner2 {
  margin-bottom: 2.4rem;
}
.terms__txt--gray {
  color: var(--color-gray-737373);
}
/* terms-scroll 안에 들어갈때 */
.privacy__box {
  padding-bottom: 1.6rem;
}
.privacy__box + .privacy__box {
  padding-top: 1.6rem;
  border-top: 1px dashed var(--color-gray-d9d9d9);
}
.privacy__box .tit {
  position: relative;
  margin-bottom: 1rem;
  color: var(--color-black);
  font-size: 1.8rem;
  font-weight: 500;
}
.privacy__box p + ul[class*='ul__'],
.privacy__box ul[class*='ul__'] + p {
  margin-top: 1rem;
}

@media all and (min-width: 391px) and (max-width: 576px) {
  html {
    font-size: 1.475vw; /* 10 -> 8.5 */
    font-size: 1.5625vw; /* 10 -> 9 */
  }
}

@media all and (max-width: 390px) {
  html {
    font-size: 2.0512vw; /* 10 -> 8 */
    font-size: 2.1794vw; /* 10 -> 8.5 */
  }
}
